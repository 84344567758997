<template>
  <div>
    <div v-if="mode === 'DESIGN'">
      <el-input size="medium" disabled :placeholder="$t(placeholder)" type="number"/>
    </div>
    <div v-else>
      <el-input v-if="!readerMode" v-model="_value" size="medium" clearable :placeholder="$t(placeholder)" type="number"/>
      <div v-else v-text="_value"></div>
    </div>
  </div>
</template>

<script>
import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "NumberInput",
  components: {},
  props:{
    value:{
      type: Number,
      default: null
    },
    placeholder:{
      type: String,
      default: 'formItem.numberPlaceholder'
    },
    readerMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
